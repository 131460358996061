<template>
  <a-modal v-model="visible" :title="false" width="65%" :footer="null" @cancel="_close" centered destroyOnClose>
    <div class="content">
      <a-row :gutter="15">
        <a-col :span="6">
          <a-button @click="Exportex" type="primary">导出</a-button>
        </a-col>
      </a-row>
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="维护记录">
          <a-table :columns="Maintaincolumns" :defaultExpandAllRows="true" :scroll="{ y: 300 }" :pagination="false"
                   :indentSize="20" :style="{ background: '#FFF' }" size="small" row-key="id" border ref="list"
                   :loading="loading" :data-source="MaintainData.records">
          </a-table>
          <a-row>
            <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
              <a-pagination :defaultPageSize="param.limit" :total="MaintainData.total" @change="getNext" show-size-changer
                            :pageSizeOptions="pageSizeOptions" @showSizeChange="setLimit" :current="MaintainData.current">
                <template slot="buildOptionText" slot-scope="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="2" tab="申请记录" force-render>
          <a-table :columns="Recordcolumns" :defaultExpandAllRows="true" :scroll="{ y: 300 }" :pagination="false"
                   :indentSize="20" :style="{ background: '#FFF' }" size="small" row-key="applyId" border ref="list"
                   :loading="loading" :data-source="RecordData.records">
          </a-table>
          <a-row>
            <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
              <a-pagination :defaultPageSize="param.limit" :total="RecordData.total" @change="getNext" show-size-changer
                            :pageSizeOptions="pageSizeOptions" @showSizeChange="setLimit" :current="RecordData.current">
                <template slot="buildOptionText" slot-scope="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-modal>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import ExporttoExcel from "@/common/ExportExcel.js";
export default {
  data() {
    return {
      visible: false,
      loading: false,
      pageSizeOptions: ['10', '20', '30', '50', '100'],
      MaintainData: {},
      RecordData: {},
      key: 1,
      Maintaincolumns: [
        { title: '车牌号', dataIndex: 'carNumber', width: 100, align: 'center' },
        { title: '维护日期', dataIndex: 'date', width: 160, align: 'center' },
        { title: '维护费用', dataIndex: 'money', width: 100, align: 'center' },
        { title: '类型', dataIndex: 'type', width: 100 },
        { title: '维护原因', dataIndex: 'cause', width: 160 },
        { title: '经办人', dataIndex: 'person', width: 100 },
      ],
      Recordcolumns: [
        { title: '车牌号', dataIndex: 'carNumber', width: 200, ellipsis: true, align: 'center' },
        { title: '用车人', dataIndex: 'name', width: 160, ellipsis: true },
        { title: '申请原因', dataIndex: 'content', ellipsis: true },
        { title: '申请时间', dataIndex: 'createTime', width: 160, align: 'center', ellipsis: true },
        { title: '调度人', dataIndex: 'assignName', width: 160, align: 'center', ellipsis: true },
        { title: '状态', dataIndex: 'status', align: 'center', width: 100, ellipsis: true }
      ],
      param: {
        current: 1,
        limit: 20,
        carId: '',
        organId: apiUtil.getOrganId('')
      }
    };

  },
  methods: {
    ...apiUtil,
    show(carId) {
      console.log(carId);
      this.param.carId = carId;
      this.visible = true;
      this.getMaintain();
    },
    _close() {
      this.visible = false;
    },
    callback(key) {
      this.key = key;
      if (key == 1) {
        // console.log(1);

        this.getMaintain();
      }
      if (key == 2) {
        console.log(2);
        this.getRecord();
      }
    },
    // 维护记录
    getMaintain() {
      this.loading = true;
      this.http
        .get('/platform/car/maintain/list', this.param)
        .then(ret => {
          this.MaintainData = ret.data;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        });
    },
    // 申请记录
    getRecord() {
      this.loading = true;
      this.http
        .get('/platform/car/record/list', this.param)
        .then(ret => {
          this.RecordData = ret.data;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        });
    },

    // 导出
    Exportex() {
      console.log(this.key);
      if (this.key == 1) {
        if (this.MaintainData.records.length > 0) {
          const filterData = this.MaintainData.records.map((item) => {
            return { '车牌号': item.carNumber, "维护日期": item.date, "维护费用": item.money, "维护类型": item.type, "维护原因": item.cause, "经办人": item.person, };
          });
          ExporttoExcel(filterData, `${this.MaintainData.records[0].carNumber}维护记录表.xlsx`);
        } else {
          this.$message.info('没有数据哦！！!');
          return;
        }
      } else {
        if (this.RecordData.records.length > 0) {
          const filterData = this.RecordData.records.map((item) => {
            return { '车牌号': item.carNumber, "用车人": item.name, "申请原因": item.content, "申请时间": item.createTime, "调度人": item.assignName, "状态": item.status, };
          });
          ExporttoExcel(filterData, `${this.RecordData.records[0].carNumber}申请记录表.xlsx`);
        } else {
          this.$message.info('没有数据哦！！!');
          return;
        }
      }


    },
    //设置每页的显示条数
    setLimit(current, limit) {
      this.param.page = 1;
      this.param.limit = limit;
      if (this.key == 1) {
        this.getMaintain();
      } else {
        this.getRecord();
      }
    },
    //页码切换
    getNext(page) {
      this.param.page = page;
      if (this.key == 1) {
        this.getMaintain();
      } else {
        this.getRecord();
      }
    }
  }
};
</script>

<style scoped>
.content {
  box-sizing: border-box;
  position: relative;
  padding: 16px 24px;
}
</style>